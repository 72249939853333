import { schemas } from "../generated/api/client"
import { z } from "zod"
import IconButtonWithTooltip, { IconButtonWithTooltipProps } from "./IconButtonWithTooltip"

type EBulkApplicationsWithMeta = z.infer<typeof schemas.ReturnType_GetEBulkApplications>
type EBulkApplication = EBulkApplicationsWithMeta['rows'][number]

type TrackWithDBSIconButtonProps = {
  showENumber?: boolean,
  dbs_enumber: EBulkApplication['dbs_enumber'],
  DateOfBirth: EBulkApplication['data']['DateOfBirth']
} & Omit<IconButtonWithTooltipProps, 'onclick'>

/**
 * Track an application at any stage that has an E-Number and a Date of Birth
 */
const TrackWithDBSIconButton: React.FC<TrackWithDBSIconButtonProps> = ({ showENumber, dbs_enumber, DateOfBirth, ...iconButtonWithProps }) => {
  return dbs_enumber != null && DateOfBirth != null
    ? <>
      <form action="https://secure.crbonline.gov.uk/enquiry/enquirySearchAction.do" method="POST" target="_blank">
        {showENumber ? <span>{dbs_enumber}</span> : null}
        <input type="hidden" name="applicationNo" value={dbs_enumber} />
        <input type="hidden" name="dateOfBirthDay" value={new Date(DateOfBirth).getDate().toString().padStart(2, '0')} />
        <input type="hidden" name="dateOfBirthMonth" value={new Date(DateOfBirth).getMonth() + 1} />
        <input type="hidden" name="dateOfBirthYear" value={new Date(DateOfBirth).getFullYear()} />
        <IconButtonWithTooltip {...iconButtonWithProps} />
      </form>
    </>
    : null
}

export default TrackWithDBSIconButton

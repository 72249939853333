import React, { useEffect, useMemo, useRef } from "react";
import { Alert, AlertTitle, Autocomplete, Button, Container, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { getIdentityDocumentsForMeetingData, MeetingData, SignatureBox, useMeetingData, useValidator, zMeetingData } from "./ApplicationMeetingData";
import { CheckboxElement, Controller, FieldPath, useForm } from "react-hook-form-mui";
import { zodResolver } from "@hookform/resolvers/zod";
import { useLinkToMeeting } from "./ApplicationMeeting";
import { apiClient, apiClientHooks } from "../../bootstrapping/InitApiClient";
import { queryClient } from "../../bootstrapping/InitReactQuery";
import { isSomething } from "../../utils/utils";
import TestApplicationAlert from "../../components/TestApplicationAlert";

type SelectDocumentCountProps = {
  form: ReturnType<typeof useForm<MeetingData>>,
  name: FieldPath<MeetingData>,
  maxDocuments?: number,
}
const SelectDocumentCount: React.FC<SelectDocumentCountProps> = ({ form, name, maxDocuments = 0 }) => {
  const options = useMemo(() => Array.from({ length: maxDocuments + 1 }, (_, i) => `${i}`), [maxDocuments])
  return <Controller
    control={form.control}
    name={name}
    render={({ field, fieldState: { error } }) => <Autocomplete
      options={options}
      disableClearable
      renderInput={(params) => <TextField {...params} error={!!error} helperText={error && error.message} />}
      value={`${field.value ?? 0}`}
      onChange={(_, value) => {
        field.onChange(parseInt(value))
        form.trigger(name)
      }}
    />}
  />
}

export const Element: React.FC = () => {
  const navigate = useNavigate()

  const { validator, isValidValidator } = useValidator()

  const { linkTo, application_id } = useLinkToMeeting()

  const { meetingData, setMeetingData, navigateWithMeetingData } = useMeetingData()
  const documentCount = useMemo(() => getIdentityDocumentsForMeetingData(meetingData).length, [meetingData])

  const getEBulkApplicationByID = apiClientHooks.useGetEBulkApplicationByID({ params: { id: application_id ?? '' } })

  const doSaveSelectionAndReturn = async () => {
    await apiClient.confirmMyEBulkApplicationDocumentsCustomerByID(meetingData, { params: { id: application_id } })
    queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getGlobalStats') }) // Don't wait for this to complete
    queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getMyEBulkApplications') }) // Don't wait for this to complete
    queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getEBulkApplicationByID') }) // Don't wait for this to complete  
    navigate('/')
  }

  const form = useForm<MeetingData>({
    resolver: zodResolver(
      zMeetingData
        .superRefine((value, context) => {
          if ((value.confirmations?.currentAddressCount ?? 0) < 1) {
            context.addIssue({
              code: 'custom',
              message: 'At least one must have applicants current address',
              path: ['confirmations', 'currentAddressCount']
            })
          }
        })
        .superRefine((value, context) => {
          if ((value.confirmations?.dateOfBirthCount ?? 0) < 1) {
            context.addIssue({
              code: 'custom',
              message: 'At least one must have applicants Date of Birth',
              path: ['confirmations', 'dateOfBirthCount']
            })
          }
        })
        .superRefine((value, context) => {
          if ((value.confirmations?.nameAndNameChangesCount ?? 0) < 1) {
            context.addIssue({
              code: 'custom',
              message: 'At least one must have applicants current name',
              path: ['confirmations', 'nameAndNameChangesCount']
            })
          }
        })
    ),
    mode: 'onBlur',
    defaultValues: meetingData,
  })

  // When we show the form, and any time the values change, we trigger the validation to show/hide the error messages
  // We also store the values in the meetingData state
  const currentAddressCount = form.watch('confirmations.currentAddressCount')
  const dateOfBirthCount = form.watch('confirmations.dateOfBirthCount')
  const nameAndNameChangesCount = form.watch('confirmations.nameAndNameChangesCount')
  useEffect(() => {
    setMeetingData(form.getValues())
    form.trigger()
  }, [form, setMeetingData, currentAddressCount, dateOfBirthCount, nameAndNameChangesCount])

  // TODO: DBS-95 -- Replace with a getJob hook, that takes an ID and returns the customer
  // For now, we get all the jobs (unfiltered) and this will break if the customer is not in the first page of results!
  const job_id = getEBulkApplicationByID.data?.job_id
  const jobs = apiClientHooks.useGetJobs()
  const job = jobs.data?.rows.find(c => c.id === job_id)
  const isJobWithAdultFirst = job?.with_adult_first
  // Set the default value to true for the withAdultFirst checkbox, if the job has it enabled, and we haven't set it yet
  const initWithAdultFirst = useRef(false)
  useEffect(() => {
    if (isJobWithAdultFirst && !initWithAdultFirst.current) {
      form.setValue('application.withAdultFirst', true)
      setMeetingData({
        ...form.getValues(),
        application: {
          withAdultFirst: true,
        },
      })
      initWithAdultFirst.current = true
    }
  }, [form, isJobWithAdultFirst, setMeetingData])

  return <>
    <Helmet>
      <title>Application Meeting</title>
    </Helmet>
    <Container component="main" maxWidth="xs">
      <Typography component="h1" variant="h5" marginTop={8} marginBottom={3}>
        Summary of Documents
      </Typography>
      <Typography variant="body1" marginBottom={1}>
        You have seen and validated the following documents:
      </Typography>
      <ol>
        {getIdentityDocumentsForMeetingData(meetingData).map((each) => {
          const qualifier = each.groupKey.endsWith('3mo')
            ? <> <em>(within the last 3 months)</em></>
            : each.groupKey.endsWith('12mo')
              ? <> <em>(within the last year)</em></>
              : each.groupKey.endsWith('anyTime')
                ? <> <em>(must be valid)</em></>
                : ''
          return <li key={`${each.groupKey}.${each.docKey}`}>{each.doc.title}{qualifier}</li>
        })}
      </ol>
      <Button
        data-testid="back-button"
        variant="contained"
        color="inherit"
        onClick={() => {
          navigateWithMeetingData(linkTo('group2'))
        }}
        sx={{ mb: 6, mr: 2, whiteSpace: 'nowrap' }}
      >
        &lt; Go back and change your selection
      </Button>
      <Alert severity="info" sx={{ mb: 3 }}>
        <AlertTitle>Please confirm the following:</AlertTitle>
        How many documents selected contain your current address?<br />
        <br />
        <strong>
          {[
            getEBulkApplicationByID.data?.data.CurrentAddress?.Address.AddressLine1,
            getEBulkApplicationByID.data?.data.CurrentAddress?.Address.AddressLine2,
            getEBulkApplicationByID.data?.data.CurrentAddress?.Address.AddressTown,
            getEBulkApplicationByID.data?.data.CurrentAddress?.Address.AddressCounty,
            getEBulkApplicationByID.data?.data.CurrentAddress?.Address.Postcode,
            getEBulkApplicationByID.data?.data.CurrentAddress?.Address.CountryCode,
          ].filter(isSomething).join(', ')}
        </strong>
        <br />
        <br />
        <SelectDocumentCount
          form={form}
          name="confirmations.currentAddressCount"
          maxDocuments={documentCount}
        />
        <br />
        <br />

        How many documents selected contain your date of birth?<br />
        <br />
        <strong>{getEBulkApplicationByID.data?.data.DateOfBirth}</strong>
        <br />
        <br />
        <SelectDocumentCount
          form={form}
          name="confirmations.dateOfBirthCount"
          maxDocuments={documentCount}
        />
        <br />
        <br />

        How many documents selected contain your current name, and optionally any name changes?<br />
        <br />
        Current: <strong>{
          [
            getEBulkApplicationByID.data?.data.Title,
            getEBulkApplicationByID.data?.data.Forename,
            ...(getEBulkApplicationByID.data?.data.Middlenames?.Middlename ?? []),
            getEBulkApplicationByID.data?.data.PresentSurname,
          ].filter(isSomething).join(' ')}</strong>
        <br />
        {(getEBulkApplicationByID.data?.data.AdditionalApplicantDetails?.OtherForenames?.OtherForename.length ?? 0) > 0 && <>
          Other Forenames: <strong>{(getEBulkApplicationByID.data?.data.AdditionalApplicantDetails?.OtherForenames?.OtherForename ?? []).map(each => each.Name).join(', ')}</strong><br />
        </>}
        {(getEBulkApplicationByID.data?.data.AdditionalApplicantDetails?.OtherSurnames?.OtherSurname.length ?? 0) > 0 && <>
          Other Surnames: <strong>{(getEBulkApplicationByID.data?.data.AdditionalApplicantDetails?.OtherSurnames?.OtherSurname ?? []).map(each => each.Name).join(', ')}</strong><br />
        </>}
        {!!getEBulkApplicationByID.data?.data.AdditionalApplicantDetails?.BirthSurname && <>
          Surname Birth: <strong>{getEBulkApplicationByID.data?.data.AdditionalApplicantDetails?.BirthSurname}</strong>
          <br />
        </>}
        <br />
        <SelectDocumentCount
          form={form}
          name="confirmations.nameAndNameChangesCount"
          maxDocuments={documentCount}
        />
      </Alert>
      {isJobWithAdultFirst && (
        <Alert severity="info" sx={{ mb: 3 }}>
          <AlertTitle>Adult First (ISA)</AlertTitle>
          This job has Adult First enabled. Please confirm if you wish to process the application with Adult First.
          <CheckboxElement
            control={form.control}
            label="Process application with Adult First"
            name="application.withAdultFirst"
            sx={{ marginTop: 3 }}
          />
        </Alert>
      )}
      <TestApplicationAlert dbsApp={getEBulkApplicationByID.data} sx={{ marginBottom: 3 }} />
      {form.formState.isValid && (
        <Alert severity="success" sx={{ mb: 3 }}>
          <AlertTitle>Documents Validated</AlertTitle>
          By continuing you are confirming you are confident that the applicant is the person evidenced in the documents validated.
          <br />
          <br />
          <SignatureBox title='Validated by' name={validator} isValid={isValidValidator} />
          <br />
          You may be asked to demonstrate the documents you have validated at a later date. We recommend you keep secure copies while the person remains in your employment, in adherance to data protection laws & regulations.
          <Button variant="contained" fullWidth sx={{ marginTop: 3 }} disabled={!isValidValidator} onClick={doSaveSelectionAndReturn}>Validate Application</Button>
        </Alert>
      )}
    </Container>
  </>
}

export default Element

import React, { PropsWithChildren } from "react";
import { CircularProgress } from "@mui/material";
import { apiClientHooks } from "./InitApiClient";
import useMaintenanceModeState from "../hooks/useMaintenanceModeState";
import MaintenanceModal from "../components/MaintenanceModal";
import { AxiosError } from "axios";

const oneHourInMillis = 60 * 60 * 1000

const InitBackendAPI: React.FC<PropsWithChildren> = ({ children }) => {
  const maintenanceModeState = useMaintenanceModeState()

  const getSystemInfo = apiClientHooks.useGetSystemInfo({}, {
    staleTime: oneHourInMillis,
  })

  // TODO: Unknown how helpful this is in the error case
  if (getSystemInfo.isError) {
    if (getSystemInfo.error instanceof AxiosError && getSystemInfo.error.response?.status === 503) {
      return <MaintenanceModal open={maintenanceModeState} />
    } else {
      throw new Error('API Server is not available')
    }
  }
  if (getSystemInfo.isLoading) return <CircularProgress />

  return <>{children}</>
}

export default InitBackendAPI
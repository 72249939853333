import React, { useEffect } from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import { z } from "zod";
import { Form, useNavigate } from "react-router-dom";
import { apiClientHooks } from "../bootstrapping/InitApiClient";
import { Helmet } from "react-helmet-async";
import { queryClient } from "../bootstrapping/InitReactQuery";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { CheckboxElement, TextFieldElement } from "react-hook-form-mui";
import { schemas } from "../generated/api/client";
import SingleClickWaitingButton from "../components/SingleClickWaitingButton";
import HomeIcon from '@mui/icons-material/Home';

export const UpdateProfile: React.FC = () => {
  const zForm = schemas.createMyProfile_Body
  type Form = z.infer<typeof zForm>

  const form = useForm<Form>({
    resolver: zodResolver(zForm),
    mode: 'onBlur',
  })

  const myProfile = apiClientHooks.useGetMyProfile()
  const myProfileLoading = myProfile.isLoading
  useEffect(() => {
    form.reset(myProfile.data)
  }, [form, myProfile.data])

  const updateMyProfile = apiClientHooks.useUpdateMyProfile()
  const onSubmit = form.handleSubmit(async (data) => {
    await updateMyProfile.mutateAsync(data)
    queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getMyProfile') })
  })

  return <>
    <Form method="post">
      <Box
        sx={{
          marginTop: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h2" variant="h6" marginBottom={3}>
          Personal Details
        </Typography>
        <Typography variant="body1" marginBottom={3}>
          These details may be overwritten when you fill your application form.
        </Typography>
        <Typography variant="body1" marginBottom={1}>
          <TextFieldElement
            control={form.control}
            label="Given Name"
            name="given_name"
            disabled={myProfileLoading}
          />
        </Typography>
        <Typography variant="body1" marginBottom={1}>
          <TextFieldElement
            control={form.control}
            label="Family Name"
            name="family_name"
            disabled={myProfileLoading}
          />
        </Typography>
        <SingleClickWaitingButton
          variant="contained"
          onClick={onSubmit}
          disabled={myProfileLoading}
          sx={{ marginTop: 2 }}
        >
          Save Personal Details
        </SingleClickWaitingButton>
      </Box>
    </Form>
  </>
}

export const UpdateEmailPreferences: React.FC = () => {
  const zForm = schemas.ProfileDataEmailPreferences
  type Form = z.infer<typeof zForm>

  const form = useForm<Form>({
    resolver: zodResolver(zForm),
    mode: 'onBlur',
  })

  const myProfileEmailPreferences = apiClientHooks.useGetMyProfileEmailPreferences()
  const myProfileEmailPreferencesLoading = myProfileEmailPreferences.isLoading
  useEffect(() => {
    form.reset(myProfileEmailPreferences.data)
  }, [form, myProfileEmailPreferences.data])

  const updateMyProfileEmailPreferences = apiClientHooks.useUpdateMyProfileEmailPreferences()
  const onSubmit = form.handleSubmit(async (data) => {
    await updateMyProfileEmailPreferences.mutateAsync(data)
    queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getMyProfile') })
    queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getMyProfileEmailPreferences') })
  })

  return <>
    <Form method="post">
      <Box
        sx={{
          marginTop: 5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h2" variant="h6" marginBottom={3}>
          Email Preferences
        </Typography>
        <Typography variant="body1" marginBottom={3}>
          You can choose which emails you'll recieve here. You will always recieve essential emails.
        </Typography>
        <Typography variant="body1" marginBottom={1}>
          <CheckboxElement
            control={form.control}
            label="Updates for Application Tracking"
            name="application_tracking"
            disabled={myProfileEmailPreferencesLoading}
          />
        </Typography>
        <SingleClickWaitingButton
          variant="contained"
          onClick={onSubmit}
          disabled={myProfileEmailPreferencesLoading}
          sx={{ marginTop: 2 }}
        >
          Save Email Preferences
        </SingleClickWaitingButton>
      </Box>
    </Form>
  </>
}

export const Element: React.FC = () => {
  const navigate = useNavigate()

  return <>
    <Helmet>
      <title>Profile</title>
    </Helmet>
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5" marginBottom={3}>
          Profile
        </Typography>
        <Typography variant="body1" marginBottom={1}>
          You can view and update your profile information here.
        </Typography>
        <UpdateProfile />
        <UpdateEmailPreferences />
        <Button
          variant="contained"
          color="secondary"
          startIcon={<HomeIcon />}
          onClick={() => {
            navigate('/')
          }}
          sx={{ marginTop: 3, marginBottom: 2 }}
        >
          Return to the Homepage
        </Button>
      </Box>
    </Container>
  </>
}

export default Element

import React, { useCallback } from "react";
import { Alert, AlertTitle, Box, Container, Typography } from "@mui/material";
import { Form, useParams } from "react-router-dom";
import { apiClient, apiClientHooks } from "../../bootstrapping/InitApiClient";
import { Helmet } from "react-helmet-async";
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
if (!process.env.REACT_APP_STRIPE_API_PUBLISHABLE_KEY) throw new Error('REACT_APP_STRIPE_API_PUBLISHABLE_KEY is required')
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_PUBLISHABLE_KEY);
console.log(`Using Stripe API Key: [${process.env.REACT_APP_STRIPE_API_PUBLISHABLE_KEY}]`)

export const Element: React.FC = () => {
  const { customer_id, application_id } = useParams<{ customer_id?: string, application_id?: string }>()
  if (!customer_id) throw new Error('customer_id is required')
  if (!application_id) throw new Error('application_id is required')

  // Get the Stripe Pricing
  const getPricing = apiClientHooks.useGetPricing()
  const isTestMode = getPricing.data?.mode === 'test'

  // Get the Stripe Checkout
  const fetchClientSecret = useCallback(async () => {
    const checkout = await apiClient.stripeCheckoutForEBulkApplicationByID({
      params: {
        id: application_id,
      },
      queries: {
        source_app: 'customer',
        relative_return_path: `${customer_id}/applications/${application_id}/payment/stripe/complete`,
      },
    })
    if (!checkout.client_secret) throw new Error('client_secret is required')
    return checkout.client_secret
  }, [customer_id, application_id]);

  return <>
    <Helmet>
      <title>Pay by Stripe</title>
    </Helmet>
    <Container component="main" maxWidth="xs">
      <Form method="post">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5" marginBottom={3}>
            Pay by Stripe {isTestMode && <span style={{ color: 'red' }}>(TEST MODE)</span>}
          </Typography>
          {isTestMode && (
            <Alert severity="info" sx={{ marginBottom: 3 }}>
              <AlertTitle>Test Card Details</AlertTitle>
              Use the following test card details, with any future expiry date, CVC, and postal code:<br />
              <br />
              Card Number: <strong>4242 4242 4242 4242</strong><br />
              The card payment succeeds and doesn't require authentication.<br />
              <br />
              Card Number: <strong>4000 0025 0000 3155</strong><br />
              The card requires <a href="https://docs.stripe.com/strong-customer-authentication">authentication</a> to complete the payment.<br />
              <br />
              Card Number: <strong>4000 0000 0000 9995</strong><br />
              The card is declined with a decline code like <em>insufficient_funds</em>.<br />
              <br />
              Card Number: <strong>6205 5000 0000 0000 004</strong><br />
              The UnionPay card has a variable length of 13-19 digits.	<br />
              <br />
            </Alert>
          )}
          <div id="checkout" style={{ width: '100%' }}>
            <EmbeddedCheckoutProvider
              stripe={stripePromise}
              options={{
                fetchClientSecret,
              }}
            >
              <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
          </div>
        </Box>
      </Form>
    </Container>
  </>
}

export default Element

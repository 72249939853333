import React from 'react';
import { Modal, Box, Typography } from '@mui/material';
import SingleClickWaitingButton from './SingleClickWaitingButton';
import { queryClient } from '../bootstrapping/InitReactQuery';
import { apiClientHooks } from '../bootstrapping/InitApiClient';

type MaintenanceModalProps = {
  open: boolean;
};

const MaintenanceModal: React.FC<MaintenanceModalProps> = ({ open }) => {
  return (
    <Modal open={open}>
      <Box sx={{ ...modalStyle }}>
        <Typography variant="h6" component="h2">
          System Maintenance
        </Typography>
        <Typography sx={{ mt: 2 }}>
          The system is currently under maintenance. Please try again later.
        </Typography>
        <SingleClickWaitingButton
          variant="contained"
          sx={{ mt: 2 }}
          onClick={() => queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getSystemInfo') })}
        >
          Check Again
        </SingleClickWaitingButton>
      </Box>
    </Modal>
  );
};

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default MaintenanceModal;

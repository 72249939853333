import { useEffect, useState } from "react"

export const maintenanceMode = (() => {
  let status: boolean | undefined = undefined
  const callbacks: ((status: boolean) => void)[] = []
  return {
    get: () => status,
    set: (newStatus: boolean) => {
      if (status === newStatus) return
      status = newStatus
      for (const callback of callbacks) callback(status)
    },
    registerCallback: (callback: (status: boolean) => void) => {
      callbacks.push(callback)
      if (status !== undefined) callback(status)
    },
    unregisterCallback: (callback: (status: boolean) => void) => {
      const index = callbacks.indexOf(callback)
      if (index !== -1) callbacks.splice(index, 1)
    },
  }
})()

const useMaintenanceModeState = () => {
  const [maintenanceModeState, setMaintenanceModeState] = useState(false)

  useEffect(() => {
    const callback = (status: boolean) => setMaintenanceModeState(status)
    maintenanceMode.registerCallback(callback)
    return () => maintenanceMode.unregisterCallback(callback)
  }, [])

  return maintenanceModeState
}

export default useMaintenanceModeState

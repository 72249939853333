import { DependencyList, PropsWithChildren, useEffect } from 'react'
import { pluginToken } from '@zodios/plugins'
import { createApiClient } from "../generated/api/client"
import { ZodiosHooks } from '../utils/zodios-react'
import { maintenanceMode } from '../hooks/useMaintenanceModeState'
import axios from 'axios'

const {
  REACT_APP_DBS_API_URL_LOCAL,
  REACT_APP_DBS_API_URL_PUBLIC,
} = process.env

// Pick the correct API URL based on the browser URL
export const isBrowserLocal = window.location.href.includes('//localhost')
export const REACT_APP_DBS_API_URL = isBrowserLocal
  ? REACT_APP_DBS_API_URL_LOCAL
  : REACT_APP_DBS_API_URL_PUBLIC

console.log('[InitApiClient] Using API URL: ', REACT_APP_DBS_API_URL, { isBrowserLocal })
if (!REACT_APP_DBS_API_URL) throw new Error('Neither REACT_APP_DBS_API_URL_LOCAL or REACT_APP_DBS_API_URL_PUBLIC is not set')

// Build the Axios Client
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

axiosInstance.interceptors.response.use(
  response => {
    maintenanceMode.set(false)
    return response
  },
  error => {
    if (error.response?.status === 503) {
      maintenanceMode.set(true)
    } else {
      maintenanceMode.set(false)
    }
    return Promise.reject(error);
  }
);

// Load the Zodios API Client
export const apiClient = createApiClient(REACT_APP_DBS_API_URL, { axiosInstance })

// Expose the API as React Hooks
export const apiClientHooks = new ZodiosHooks('dbs-api', apiClient)

// Enable the Loading / Unloading of Authentication Tokens
export const useRegisterAuthTokenProvider = (type: 'auth0' | 'profile', getToken: () => Promise<string>, deps: DependencyList) => {
  useEffect(() => {
    const isProtectedByAuth0JWT = (path: string, method: string) => path === '/profile/my' && ['get', 'post'].includes(method)
    const isProtectedByProfileJWT = (path: string, method: string) => !isProtectedByAuth0JWT(path, method)

    const pluginIds = apiClient.api
      .filter(endpoint => {
        if (type === 'auth0' && isProtectedByAuth0JWT(endpoint.path, endpoint.method)) return true
        if (type === 'profile' && isProtectedByProfileJWT(endpoint.path, endpoint.method)) return true
        return false
      })
      .map(endpoint => apiClient.use(endpoint.alias, pluginToken({ getToken })))

    // Eject the tokens
    return () => {
      for (const pluginId of pluginIds) apiClient.eject(pluginId)
    }
  }, [type, getToken, deps])
}

apiClient.getSystemInfo().then(systemInfo => console.log('[InitApiClient] System Info:', systemInfo))

const InitApiClient: React.FC<PropsWithChildren> = ({ children }) => {
  // This component isn't really needed, but using it here forces the global exports to be loaded at a certain point in the app lifecycle
  if (process.env.NODE_ENV === 'development') console.log('[InitApiClient] API Client loaded!')

  return <>{children}</>
}

export default InitApiClient

import React, { ComponentProps, Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { Alert, AlertTitle, Button, Card, CardContent, Checkbox, FormHelperText, Typography } from "@mui/material";
import { RefinementCtx, z } from "zod";
import { useLocation, useNavigate } from "react-router-dom";
import { isSomething } from "../../utils/utils";
import { schemas } from "../../generated/api/client";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import StringFormattedControlledDatePicker from "../../components/StringFormattedControlledDatePicker";
import { TextFieldElement } from "react-hook-form-mui";
import { addMonths, isBefore } from "date-fns";
import { useProfileAssured } from "../../bootstrapping/InitRequireProfile";

/////////////////////////////////////////////////////////////////////////////
/// NOTE! This is a common file between applicant and customer applications
/////////////////////////////////////////////////////////////////////////////

const colourSuccessBackground = '#d4edda'
const colourErrorBackground = '#f8d7da'

export const zMeetingData = schemas.EBulkApplicationDocuments
export type MeetingData = z.infer<typeof zMeetingData>
export type EBulkApplicationDocument = z.infer<typeof schemas.EBulkApplicationDocument>

type IdentityDocument = {
  title: string,
  description?: string,
  required?: boolean,
  // What does the document contain
  hasName?: boolean,
  hasAddress?: boolean,
  hasDOB?: boolean,
  // If this document is not selectable, why? Returning undefined or not implementing means it's selectable.
  notSelectableBecause?: (meetingData: MeetingData) => undefined | string,
  // For the Identity Verification Meeting
  // All ID checks have a checkbox asking "I confirm I have verified this document"
  // These are for asking for a specific string to be entered and then checking it against the eBulkApplication (such as Passport Number)
  customIdCheckLabel?: string,
  customIdCheckMatches?: (eBulkApplication: z.infer<typeof schemas.EBulkApplication>) => string | undefined,
  // For documents which have limited validity from issue, we can add a window of time
  validForMonths?: number,
}

type IdentityDocumentGroups = {
  group1?: { [key: string]: IdentityDocument },
  group2a?: { [key: string]: IdentityDocument },
  group2b_last3mo?: { [key: string]: IdentityDocument },
  group2b_last12mo?: { [key: string]: IdentityDocument },
  group2b_anyTime?: { [key: string]: IdentityDocument },
}

export const identityDocuments = {
  group1: {
    passport: {
      title: "Current valid passport",
      hasName: true,
      hasDOB: true,
      customIdCheckLabel: "Enter passport number",
      customIdCheckMatches: (eBulkApplication) => eBulkApplication.data.ApplicantIdentityDetails?.PassportDetails?.PassportNumber,
    },
    driverLicence: {
      title: "Current UK, Channel Isles or Isle of Man driver licence",
      description: "Photo card - full or provisional. All licences must be valid in line with current DVLA requirements",
      hasName: true,
      hasAddress: true,
      hasDOB: true,
      customIdCheckLabel: "Enter driver licence number",
      customIdCheckMatches: (eBulkApplication) => eBulkApplication.data.ApplicantIdentityDetails?.DriverLicenceDetails?.DriverLicenceNumber,
    },
    birthCertificate: {
      title: "Birth certificate – issued within 12 months of birth",
      description: "UK and Channel Islands - including those issued by UK authorities (overseas, for example embassies, High Commissions and HM Forces)",
      hasName: true,
      hasDOB: true,
      notSelectableBecause: meetingData => {
        if (meetingData.group2a?.birthCertificate?.selected === true) return "Group 2a birth certificate (after 12 months of birth) is already selected"
        return undefined
      },
    },
    adoptionCertificate: {
      title: "Adoption certificate UK & Channel Islands",
      hasName: true,
      hasDOB: true,
    },
  },
  group2a: {
    driverLicenceNonEEA: {
      title: "Current driver licence (full or provisional)",
      description: "All countries outside of the EEA (excluding Isle of Man and Channel Islands)",
      hasName: true,
      hasAddress: true,
      hasDOB: true,
      notSelectableBecause: meetingData => {
        if (meetingData.group1?.driverLicence?.selected === true) return "Group 1 driver licence is already selected"
        if (meetingData.group2a?.driverLicencePaper?.selected === true) return "Group 2a driver licence (paper before 1998) is already selected"
        return undefined
      },
    },
    driverLicencePaper: {
      title: "Current driver licence (full or provisional) - paper version, issued before 1998",
      description: "UK, Isle of Man, Channel Islands and EEA",
      hasName: true,
      hasAddress: true,
      hasDOB: true,
      notSelectableBecause: meetingData => {
        if (meetingData.group1?.driverLicence?.selected === true) return "Group 1 driver licence is already selected"
        if (meetingData.group2a?.driverLicenceNonEEA?.selected === true) return "Group 2a driver licence (Non-EEA) is already selected"
        return undefined
      },
    },
    birthCertificate: {
      title: "Birth Certificate - issued 12 months or more after date of birth",
      description: "UK - Isle of Man and Channel Islands",
      hasName: true,
      hasDOB: true,
      notSelectableBecause: meetingData => {
        if (meetingData.group1?.birthCertificate?.selected === true) return "Group 1 birth certificate is already selected"
        return undefined
      },
    },
    marriageCivilPartnershipCertificate: {
      title: "Marriage/Civil Partnership Certificate",
      description: "UK and Channel Islands",
      hasName: true, // maiden name
      hasAddress: true, // at time of marriage
      hasDOB: true,
    },
    hmForcesIdCard: {
      title: "HM Forces ID Card (UK)",
      hasName: true,
      hasDOB: true,
    },
    validFirearmsLicence: {
      title: "Valid Firearms Licence",
      description: "UK, Isle of Man and Channel Islands",
      hasName: true,
      hasAddress: true,
      hasDOB: true,
    },
    immigrationDocumentVisaWorkPermit: {
      title: "Immigration Document/Visa/Work Permit",
      description: "Valid only for roles whereby the applicant is living and working outside of the UK. Visa/permit must relate to the non UK country in which the role is based",
      hasName: true,
      hasDOB: true,
    },
  },
  group2b_last3mo: {
    bankStatementUKCI: {
      title: "Bank/Building Society Statement",
      description: "UK and Channel Islands",
      validForMonths: 3,
      hasName: true,
      hasAddress: true,
    },
    bankStatementUKCI_NonEEA: {
      title: "Bank/Building Society Statement",
      description: "Countries outside the EEA - branch must be in the country where the applicant lives and works",
      validForMonths: 3,
      hasName: true,
      hasAddress: true,
    },
    bankOpeningConfirmationLetter: {
      title: "Bank/Building Society Opening Confirmation Letter",
      description: "UK",
      validForMonths: 3,
    },
    creditCardStatement: {
      title: "Credit Card Statement",
      description: "UK or EEA",
      validForMonths: 3,
      hasName: true,
      hasAddress: true,
    },
    utilityBill: {
      title: "Utility Bill",
      description: "UK. Electricity, gas, water, telephone. Not mobile phone bill",
      validForMonths: 3,
      hasName: true,
      hasAddress: true,
    },
    benefitStatement: {
      title: "Benefit Statement",
      description: "UK. Child allowance, pension, etc",
      validForMonths: 3,
      hasName: true,
      hasAddress: true,
    },
    governmentDocumentGivingEntitlement: {
      title: "Central or local government, government agency, or local council document giving entitlement",
      description: "e.g. from the Department for Work and Pensions, the Employment Service, HMRC",
      validForMonths: 3,
      hasName: true,
      hasAddress: true,
    },
    letterSponsorshipFutureEmployer: {
      title: "Letter of sponsorship from future employment provider",
      description: "Non-UK or non-EEA only - valid only for applicants residing outside the UK at the time of application",
      validForMonths: 3,
      hasName: true,
      hasAddress: true,
      hasDOB: true,
    },
  },
  group2b_last12mo: {
    mortgageStatement: {
      title: "Mortgage Statement",
      description: "UK or EEA",
      validForMonths: 12,
      hasName: true,
      hasAddress: true,
    },
    financialStatement: {
      title: "Financial Statement (e.g. pension, endowment)",
      description: "UK",
      validForMonths: 12,
      hasName: true,
      hasAddress: true,
    },
    p45_p60: {
      title: "P45/P60 Statement",
      description: "UK and Channel Islands",
      validForMonths: 12,
      hasName: true,
      hasAddress: true,
      hasDOB: true,
    },
    councilTaxStatement: {
      title: "Council Tax Statement",
      description: "UK and Channel Islands",
      validForMonths: 12,
      hasName: true,
      hasAddress: true,
    },
  },
  group2b_anyTime: {
    euNationalIdentityCard: {
      title: "EU / EEA National Identity Card",
      hasName: true,
      hasDOB: true,
    },
    irishPassportCard: {
      title: "Irish Passport Card",
      description: "Cannot be used with an Irish passport",
      hasName: true,
      hasDOB: true,
    },
    letterHeadteacher: {
      title: "Letter from head teacher or college principal",
      description: "UK. For 16 to 19 year olds in full time education. Only used in exceptional circumstances if other documents cannot be provided",
      hasName: true,
      hasAddress: true,
      hasDOB: true,
    },
    passLogoCard: {
      title: "Card carrying the PASS accreditation logo",
      description: "UK and Channel Islands",
      hasName: true,
      hasDOB: true,
    },
  },
} satisfies IdentityDocumentGroups

export const IdentityDocumentCard: React.FC<{
  document: IdentityDocument,
  meetingData: MeetingData,
  // If mandatory is 'present', this document must be selected
  // If mandatory is 'not-present', this document cannot be selected
  // If mandatory is undefined, this document is optionally selectable
  mandatory?: 'present' | 'not-present',
  // Select this document to be verfiied (by applicant, or by customer during verification)
  selected?: boolean,
  onSelect?: () => void,
  // Mark this document as verified (by customer during verification, we don't use this during applicant selection)
  verified?: boolean,
  onVerify?: () => void,
  eBulkApplication?: z.infer<typeof schemas.EBulkApplication>
} & ComponentProps<typeof Card>> = ({ document, meetingData, mandatory, selected, onSelect, verified, onVerify, eBulkApplication, ...cardProps }) => {
  // If required, ensure this is selected or isn't selected based on the mandatory status
  useEffect(() => {
    if (mandatory === 'present' && !selected) {
      onSelect?.()
    }
    if (mandatory === 'not-present' && selected) {
      onSelect?.()
    }
  }, [mandatory, selected, onSelect])

  // Build the form for the document constraints (selected and verified are managed externally)
  // These constraints are for determining whether the verification checkbox can be checked (or if it should be auto-unchecked)
  const zCardForm = z.object({
    issueDate: z.string().optional(),
    customIdCheckValue: z.string().optional(),
  })
  type CardForm = z.infer<typeof zCardForm>
  const cardForm = useForm<CardForm>({
    resolver: zodResolver(
      zCardForm
        .superRefine((value, context) => {
          // If there's custom ID checks specified for this document
          // Ensure it matches what the applicant entered
          if (document.customIdCheckLabel && document.customIdCheckMatches && eBulkApplication) {
            const customIdCheckApplicationValue = document.customIdCheckMatches(eBulkApplication)
            const customIdCheckMatches = customIdCheckApplicationValue === value.customIdCheckValue
            if (!customIdCheckMatches) {
              context.addIssue({
                code: 'custom',
                message: `This does not match what the applicant entered: ${customIdCheckApplicationValue}`,
                path: ['customIdCheckValue'],
              })
            }
          }
        })
        .superRefine((value, context) => {
          // If there's a validity window specified for this document
          if (document.validForMonths) {
            // Check the date is entered
            if (!value.issueDate) {
              context.addIssue({
                code: 'custom',
                message: `You must enter the issue date`,
                path: ['issueDate'],
              })
            } else {
              // Check the date is within the validity window
              const issueDate = new Date(value.issueDate)
              const issueDateWithValidity = addMonths(issueDate, document.validForMonths)
              const isValid = !isBefore(issueDateWithValidity, new Date())
              if (!isValid) {
                context.addIssue({
                  code: 'custom',
                  message: `The document must be within ${document.validForMonths} months of issue`,
                  path: ['issueDate'],
                })
              }
            }
          }
        })
    ),
    mode: 'onChange',
    defaultValues: {
      customIdCheckValue: '',
      issueDate: '',
    },
  })

  // Trigger the form validation on mount, so errors show up immediately
  useEffect(() => {
    cardForm.trigger()
  }, [cardForm])

  // Everytime our form data changes, if we're verified but the form is invalid, we need to re-verify (to un-verify)
  const customIdCheckValue = cardForm.watch('customIdCheckValue')
  const issueDate = cardForm.watch('issueDate')
  useEffect(() => {
    if (verified === true && !cardForm.formState.isValid) {
      onVerify?.()
    }
  }, [customIdCheckValue, issueDate, verified, cardForm.formState.isValid, onVerify])

  const notSelectableBecause = document.notSelectableBecause?.(meetingData)
  const isNotSelectable = notSelectableBecause !== undefined

  // Display the card
  return (
    <Card
      {...cardProps}
      sx={{
        backgroundColor: selected
          ? colourSuccessBackground
          : mandatory === 'not-present'
            ? colourErrorBackground
            : isNotSelectable
              ? 'grey.200'
              : 'inherit',
        ...(cardProps.sx ?? {}),
      }}
    >
      <CardContent>
        <Typography variant="h5" component="div">
          {document.title} {selected && '✅'} {isNotSelectable && '🚫'}
        </Typography>
        {document.description && (
          <Typography variant="body2" color="text.secondary">
            {document.description}
          </Typography>
        )}
      </CardContent>
      <Button
        variant="contained"
        color={selected ? "success" : "primary"}
        disabled={mandatory === 'present' || mandatory === 'not-present' || isNotSelectable}
        onClick={onSelect}
        sx={{ margin: 2 }}
      >
        {selected
          ? "SELECTED"
          : mandatory === 'not-present'
            ? "NOT PRESENT ON APPLICATION"
            : isNotSelectable
              ? notSelectableBecause
              : "Pick"}
      </Button>
      {selected && onVerify && (
        <Alert severity="info" sx={{ margin: 0 }}>
          <AlertTitle>Document Verification</AlertTitle>
          {document.customIdCheckLabel && document.customIdCheckMatches && <>
            <TextFieldElement
              control={cardForm.control}
              name="customIdCheckValue"
              label={document.customIdCheckLabel}
              fullWidth
              sx={{ marginTop: 1 }}
            />
          </>}
          {document.validForMonths && <>
            <Typography variant="body2" marginTop={1} marginBottom={1}>
              The document must be within {document.validForMonths} months of issue.
            </Typography>
            <StringFormattedControlledDatePicker
              control={cardForm.control}
              label="Issue Date"
              name={'issueDate'}
              views={['day', 'month', 'year']}
              format="yyyy-MM-dd"
              sx={{ marginTop: 1 }}
            />
          </>}
          {/* Hide the checkbox while the custom function doesn't match and force value to false */}
          {/* We don't need to store what value they entered, just that it's been validated -- it's on the customer to keep their own copies of their verification */}
          <Typography variant="body2" marginTop={1} marginBottom={1}>
            {/* This is just here for whitespace management */}
          </Typography>
          <Checkbox
            checked={verified ?? false}
            onChange={onVerify}
            sx={{
              color: !verified ? 'error.main' : undefined,
            }}
            disabled={!cardForm.formState.isValid}
          />{' '}
          I confirm I have verified this document
          {!verified && cardForm.formState.isValid && (
            <FormHelperText error={true}>You must confirm you have verified this document</FormHelperText>
          )}
        </Alert>
      )}
    </Card>
  );
}

export const toggleMeetingData = (meetingData: MeetingData, groupKey: keyof typeof meetingData, docKey: keyof typeof meetingData[typeof groupKey], toggleField: keyof EBulkApplicationDocument, setMeetingData: Dispatch<SetStateAction<MeetingData>>): MeetingData => {
  const newMeetingData = { ...meetingData }
  newMeetingData[groupKey] = newMeetingData[groupKey] ?? {}
  newMeetingData[groupKey]![docKey] = newMeetingData[groupKey]![docKey] ?? {
    selected: false,
  };
  (newMeetingData[groupKey]![docKey] as EBulkApplicationDocument)[toggleField] = !(newMeetingData[groupKey]![docKey] as EBulkApplicationDocument)?.[toggleField];
  setMeetingData(newMeetingData)
  return newMeetingData
}

type IdentityDocumentWithMetadata = {
  groupKey: string,
  docKey: string,
  doc: IdentityDocument,
}

/**
 * Run through each of the meeting data groups and selected document keys.
 * Convert the document keys to the corresponding IdentityDocument objects.
 * Return the list of IdentityDocument objects found.
 * 
 * NOTE: This expects the key structure of MeetingData and IdentityDocuments to match. The result is undefined if they don't.
 */
export const getIdentityDocumentsForMeetingData = (meetingData: MeetingData): IdentityDocumentWithMetadata[] =>
  Object.entries(meetingData)
    .flatMap(([groupKey, groupValue]) => {
      const groupDocs = identityDocuments[groupKey as keyof typeof identityDocuments] ?? {}
      return Object.entries(groupValue)
        .map(([docKey, docKeyValue]) => {
          if (!docKeyValue?.selected) return undefined
          const doc = groupDocs[docKey as keyof typeof groupDocs] as IdentityDocument | undefined
          return doc ? { groupKey, docKey, doc } : undefined
        })
        .filter(isSomething)
    })

// SuperRefine logic for MeetingData forms

export const superRefineGroup1 = (value: MeetingData, context: RefinementCtx) => {
  // Go through each of the group1 documents and ensure that the selected ones are verified
  Object.entries(value.group1 ?? {}).forEach(([key, value]) => {
    const groupKey = key as keyof typeof identityDocuments.group1
    if (value.selected === true && value.verified !== true) {
      context.addIssue({
        code: 'custom',
        message: `You must verify the document`,
        path: ['group1', groupKey, 'verified'],
      })
    }
  })
}

export const superRefineGroup2 = (value: MeetingData, context: RefinementCtx) => {
  // Go through each of the group2 documents and ensure that the selected ones are verified
  Object.entries(value.group2a ?? {}).forEach(([key, value]) => {
    const groupKey = key as keyof typeof identityDocuments.group2a
    if (value.selected && !value.verified) {
      context.addIssue({
        code: 'custom',
        message: `You must verify the document`,
        path: ['group2a', groupKey, 'verified'],
      })
    }
  })
  Object.entries(value.group2b_last3mo ?? {}).forEach(([key, value]) => {
    const groupKey = key as keyof typeof identityDocuments.group2b_last3mo
    if (value.selected && !value.verified) {
      context.addIssue({
        code: 'custom',
        message: `You must verify the document`,
        path: ['group2b_last3mo', groupKey, 'verified'],
      })
    }
  })
  Object.entries(value.group2b_last12mo ?? {}).forEach(([key, value]) => {
    const groupKey = key as keyof typeof identityDocuments.group2b_last12mo
    if (value.selected && !value.verified) {
      context.addIssue({
        code: 'custom',
        message: `You must verify the document`,
        path: ['group2b_last12mo', groupKey, 'verified'],
      })
    }
  })
  Object.entries(value.group2b_anyTime ?? {}).forEach(([key, value]) => {
    const groupKey = key as keyof typeof identityDocuments.group2b_anyTime
    if (value.selected && !value.verified) {
      context.addIssue({
        code: 'custom',
        message: `You must verify the document`,
        path: ['group2b_anyTime', groupKey, 'verified'],
      })
    }
  })
}

// MeetingData Hooks

type useMeetingDataReturns = {
  meetingData: MeetingData,
  setMeetingData: Dispatch<SetStateAction<MeetingData>>,
  navigateWithMeetingData: (path: string) => void,
}
export const useMeetingData = (): useMeetingDataReturns => {
  const location = useLocation()
  const navigate = useNavigate()

  const [localMeetingData, setLocalMeetingData] = useState<MeetingData>(
    // Initiate the meeting data with the state from the location
    () => zMeetingData.parse(location.state ?? {})
  )

  const navigateWithMeetingData = useCallback((path: string) => navigate(path, { state: localMeetingData }), [navigate, localMeetingData])

  return {
    meetingData: localMeetingData,
    setMeetingData: setLocalMeetingData,
    navigateWithMeetingData,
  }
}

export const useNavigateWithMeetingData = (meetingData: MeetingData) => {
  const navigate = useNavigate()
  return (path: string) => navigate(path, { state: meetingData })
}

// Signature / Validator

/**
 * Takes the logged in profile and converts it into a single string.
 * This is used as a signature for the person validating the documents (the validator).
 * 
 * 1. Combines the given and family names, with a space in between
 * 2. Trims the string for outside whitespace
 * 3. Converts the string to uppercase
 * 4. Limits the string to 60 characters
 * 5. Checks that the string only contains uppercase letters, spaces, and hyphens
 * 
 * @see SignatureBox
 */
export const useValidator = () => {
  const profile = useProfileAssured()
  const validator = `${profile.given_name.trim()} ${profile.family_name.trim()}`.trim().toUpperCase().slice(0, 60)
  const isValidValidator = /^[A-Z -]+$/.test(validator)

  return { validator, isValidValidator }
}

/**
 * An outlined box displaying the name of the person signing
 * @param title The title to display above the name, defaults to 'Signature'
 * @param name The name of the person signing
 * @param isValid We show an error if the name is not valid
 */
export const SignatureBox: React.FC<{ title?: string, name: string, isValid?: boolean }> = ({ title, name, isValid = true }) => {
  // Do not use input elements in this component, as it is for display only
  // Make the name red if it's invalid
  return <>
    <Typography variant="body1">{title ?? 'Signature'}:</Typography>
    <Typography variant="h6" color={isValid ? undefined : 'red'}>{name}</Typography>
    {!isValid && <Typography variant="body2" color="red">Name is not valid for signature</Typography>}
  </>
}

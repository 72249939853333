import React, { ComponentProps, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { apiClientHooks } from "../../bootstrapping/InitApiClient";
import { Alert, AlertTitle, Box, Button, Collapse, Container, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { getIdentityDocumentsForMeetingData, IdentityDocumentCard, SignatureBox, superRefineGroup1, superRefineGroup2, useMeetingData, useValidator, zMeetingData } from "./ApplicationMeetingData";
import { isSomething } from "../../utils/utils";
import { TestingInfoBlock } from "../../components/TestingInfo";
import TestApplicationAlert from "../../components/TestApplicationAlert";

export const useLinkToMeeting = () => {
  const { customer_id, application_id } = useParams<{ customer_id?: string, application_id?: string }>()

  if (!customer_id) throw new Error('customer_id is required')
  if (!application_id) throw new Error('application_id is required')

  return {
    linkTo: (path: string) => `/${customer_id}/applications/${application_id}/meeting/${path}`,
    customer_id,
    application_id,
  }
}

export const zMeetingDataWithResolversGroup1 = zMeetingData.superRefine(superRefineGroup1)
export const zMeetingDataWithResolversGroup2 = zMeetingData.superRefine(superRefineGroup2)

/**
 * With ApplicationMeetingGroup files being common across applications, but this file being specific to each application,
 * this function filters the props to only those relevant to this application.
 */
export const filterIdentityDocumentCardProps = (props: Partial<ComponentProps<typeof IdentityDocumentCard>>): Partial<ComponentProps<typeof IdentityDocumentCard>> => {
  return {
    selected: props.selected,
    onSelect: props.onSelect,
    verified: props.verified,
    onVerify: props.onVerify,
    eBulkApplication: props.eBulkApplication,
  }
}

export const Element: React.FC = () => {
  const { linkTo, application_id } = useLinkToMeeting()

  const { validator, isValidValidator } = useValidator()

  // Get the MeetingData
  const { navigateWithMeetingData, setMeetingData, meetingData } = useMeetingData()

  // Load the application
  const getEBulkApplicationByID = apiClientHooks.useGetEBulkApplicationByID({
    params: { id: application_id ?? '' },
  }, {
    enabled: !!application_id,
  })

  const documents_applicant = getEBulkApplicationByID.data?.documents_applicant

  const _hasPassport = !!getEBulkApplicationByID.data?.data.ApplicantIdentityDetails?.PassportDetails?.PassportNumber
  const _hasDriverLicenceWithPhoto = getEBulkApplicationByID.data?.data.ApplicantIdentityDetails?.DriverLicenceDetails?.DriverLicenceType === 'photo'
  const requiredDocuments = [
    _hasPassport ? 'Passport' : undefined,
    _hasDriverLicenceWithPhoto ? 'Driver Licence with Photo' : undefined,
  ].filter(isSomething)

  // Preselect the documents chosen by the applicant
  useEffect(() => {
    if (documents_applicant && Object.keys(setMeetingData).length === 0) {
      setMeetingData({
        ...documents_applicant,
        confirmations: {},
      })
    }
  }, [documents_applicant, setMeetingData])

  // UI State
  const [showDocumentGuidance, setShowDocumentGuidance] = useState(false)

  return <>
    <Helmet>
      <title>Application Review Meeting</title>
    </Helmet>
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <TestApplicationAlert dbsApp={getEBulkApplicationByID.data} sx={{ marginBottom: 3 }} />
        <Typography component="h1" variant="h5" marginBottom={3}>
          Application Review Meeting
        </Typography>

        <Typography variant="body1" marginBottom={2}>
          This meeting is to review the documents presented in order to verify the applicant's identity.{' '}
          The verifier may take copies of the documents and store them securely.{' '}
          <em>The verifier may be asked to evidence the documents they have seen and validated, if a challenge is made to the validity of the check at a later date.</em>
        </Typography>

        <Typography variant="body1" marginBottom={2}>
          <strong>Please note</strong> all documents must be original, photocopies and documents downloaded from the internet cannot be accepted.{' '}
          <Button
            variant="text"
            color="primary"
            onClick={() => { setShowDocumentGuidance(currentState => !currentState) }}
            sx={{ padding: 0, minWidth: 'auto', textTransform: 'none' }}
          >
            Why are specific documents required?
          </Button>
        </Typography>
        <Collapse in={showDocumentGuidance}>
          <Alert id="document-guidance" severity="info" sx={{ m: 0 }} onClose={() => { setShowDocumentGuidance(false) }}>
            <AlertTitle>Document Verification Information</AlertTitle>
            <Typography variant="body2" marginBottom={1}>
              In order to progress with the application, the applicant must provide sufficient ID to validate their application.
            </Typography>

            <Typography variant="body2" marginBottom={1}>
              Guidance has been produced on the type and range of ID documents that must be seen to validate the identity of the applicant.{' '}
              For full guidance consult{' '}
              <a href="https://www.gov.uk/government/publications/dbs-identity-checking-guidelines/id-checking-guidelines-for-standardenhanced-dbs-check-applications-from-1-july-2021">
                🔗 DBS Guidance – Standard & Enhanced
              </a>
            </Typography>
            <ul>
              <li>A minimum of three documents must be witnessed. At least one document should be from Group 1. If the applicant cannot provide any ID from Group 1, you should contact us.</li>
              <li>At least one document must confirm the date of birth.</li>
              <li>At least one document must confirm the current address.</li>
              <li>All documents must be original. Photocopies and documents downloaded from the internet are not acceptable.</li>
              <li>All personal details provided by the applicant should ensure the full and correct name and address history has been validated.</li>
              <li>Failure to validate the information correctly may lead to the check being invalid.</li>
            </ul>
          </Alert>
        </Collapse>

        {requiredDocuments.length > 0 && (
          <Alert severity="warning" sx={{ marginBottom: 2 }}>
            <AlertTitle>Required Documents</AlertTitle>
            The applicant has a {requiredDocuments.join(' and a ')}, which <strong>must</strong> now be validated.
          </Alert>
        )}

        <Typography variant="body1" marginBottom={1}>
          The applicant selected the following documents:
        </Typography>
        <ol>
          {getIdentityDocumentsForMeetingData(meetingData).map((each) => {
            const qualifier = each.groupKey.endsWith('3mo')
              ? <> <em>(within the last 3 months)</em></>
              : each.groupKey.endsWith('12mo')
                ? <> <em>(within the last year)</em></>
                : each.groupKey.endsWith('anyTime')
                  ? <> <em>(must be valid)</em></>
                  : ''
            return <li key={`${each.groupKey}.${each.docKey}`}>{each.doc.title}{qualifier}</li>
          })}
        </ol>

        <Typography variant="body1" marginBottom={2}>
          You will now be asked to select which document you have seen and validated in person.
        </Typography>
        <Typography variant="body1" marginBottom={2}>
          The documents have been preselected based on the applicant's choices shown above, but maybe changed (except for passport and driver licence).
        </Typography>

        <TestingInfoBlock>
          <Alert severity="info" sx={{ marginBottom: 2 }}>
            <AlertTitle>Only shown in Test Mode</AlertTitle>
            <strong>Validated by</strong> is set to the logged in user (the verifier) profile name, converted to upper case, filtered to letters, hypens and spaces only. It is truncated to 60 characters and trimmed of surrounding whitespace.
          </Alert>
        </TestingInfoBlock>

        <SignatureBox title='Validated by' name={validator} isValid={isValidValidator} />

        <Button
          data-testid="back-button"
          variant="contained"
          color="inherit"
          onClick={() => {
            window.history.back();
          }}
          sx={{ mt: 3, mr: 2, whiteSpace: 'nowrap' }}
        >
          &lt; Back
        </Button>
        <Button variant="contained" disabled={!isValidValidator} onClick={() => { navigateWithMeetingData(linkTo('group1')) }} sx={{ mt: 2 }}>
          Select Group 1 Documents
        </Button>
      </Box>
    </Container>
  </>
}

export default Element

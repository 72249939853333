import * as React from 'react';
import { IconButton, IconButtonProps, SvgIconTypeMap, SvgIconOwnProps, Tooltip, TooltipProps } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import useHasBeenUnmounted from '../hooks/useHasBeenUnmounted';
import IconOrSpinner from './LoadingSpinnerIcon';

export type IconButtonWithTooltipProps = {
  title: TooltipProps['title'];
  type?: IconButtonProps['type'];
  disabled?: IconButtonProps['disabled'];
  onClick?: IconButtonProps['onClick'];
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  color?: SvgIconOwnProps['color'];
}

const IconButtonWithTooltip: React.FC<IconButtonWithTooltipProps> = ({ title, type, disabled, icon, color, onClick }) => {
  /** State to disable the button when the onClick is in progress */
  const [inProgress, setInProgress] = React.useState(false)
  /** A fail-safe mutable lock which prevents the onClick logic running twice, in most race condition cases */
  const onClickRunning = React.useRef(false)

  const hasBeenUnmounted = useHasBeenUnmounted()

  const _onClick: NonNullable<IconButtonProps['onClick']> = async (...onClickArgs) => {
    // If there's no action, then we can short-circuit the whole dance
    if (!onClick) return

    // Set the In Progress flags (disabling the button)
    setInProgress(true)

    // Run the action
    if (onClickRunning.current) {
      console.warn(`[SingleClickWaitingIconButtonWithTooltip#${title ?? 'unnamed'}] The onClick is already running and the button was disabled, but I still got onClicked!`)
    } else {
      onClickRunning.current = true
      await onClick(...onClickArgs)
      onClickRunning.current = false
    }

    // The following will trigger a react error if the button is unmounted in the onClick (such as a page change).
    // Set the In Progress flag (enabling the button)
    if (!hasBeenUnmounted.current) {
      setInProgress(false)
    } else {
      console.debug(`[SingleClickWaitingIconButtonWithTooltip#${title ?? 'unnamed'}] The button was unmounted during the onClick, so I did not re-enable it.`)
    }
  }

  const IconComponent = icon;

  return inProgress
    ? (
      <Tooltip title={title}>
        <span>
          <IconButton color="error" type={type} disabled={true}>
            <IconOrSpinner icon={icon} color='disabled' />
          </IconButton>
        </span>
      </Tooltip>
    )
    : disabled
      ? (
        <Tooltip title={title}>
          <span>
            <IconButton color="error" type={type} disabled={true}>
              <IconComponent color='disabled' />
            </IconButton>
          </span>
        </Tooltip>
      )
      : (
        <Tooltip title={title}>
          <IconButton color="error" type={type} onClick={_onClick} disabled={false}>
            <IconComponent color={color} />
          </IconButton>
        </Tooltip>
      );
}

export default IconButtonWithTooltip;

import { RefinementCtx, z } from "zod"
import { schemas } from "../generated/api/client"

const zNewJob = schemas.NewJob
type NewJob = z.infer<typeof zNewJob>

/**
 * The PositionAppliedFor field should declare the workforce the applicant is applying to work with, and the role they are applying for.
 * The term 'workforce' can be abbreviated to 'WF' resulting in the following format: [Workforce] [Role]
 * The resulting string is converted to upper case.
 * @param job The role name and workforce the applicant is applying to work with
 */
export const computePositionAppliedFor = (job: Pick<NewJob, 'role' | 'application_workforce' | 'application_type'>): string => {
  if (job.application_type === 'standard') {
    return job.role.toUpperCase()
  }
  
  switch (job.application_workforce) {
    case 'adult_only':
      return `Adult WF ${job.role}`.toUpperCase()
    case 'child_only':
      return `Child WF ${job.role}`.toUpperCase()
    case 'child_and_adult':
      return `Child and Adult WF ${job.role}`.toUpperCase()
    default:
      return `Other WF ${job.role}`.toUpperCase()
  }
}

export const validatePositionAppliedFor = (positionAppliedFor: string): string | true => {
  // Max 60 characters
  if (positionAppliedFor.length > 60) {
    return 'Too long'
  }

  // Must match the regex pattern
  // I've taken the regex pattern from the zod schema for `schemas.NewJob.shape.role` and added the start and end anchors
  // TODO: Check if this actually matches the spec
  if (!/^([A-Z0-9\(\)\-/'&]+)|([A-Z0-9\(\)\-/'&][A-Z 0-9\(\)\-/'&]*[A-Z0-9\(\)\-/'&])$/.test(positionAppliedFor)) {
    return 'Invalid characters'
  }

  return true
}

export const rulesAddJob: (arg: NewJob, ctx: RefinementCtx) => void = async (value, context) => {
  const positionAppliedFor = computePositionAppliedFor(value)
  const validatedPositionAppliedFor = validatePositionAppliedFor(positionAppliedFor)
  if (validatedPositionAppliedFor !== true) {
    context.addIssue({
      code: 'custom',
      message: validatedPositionAppliedFor,
      path: ['role']
    })
  }
  console.log('rulesAddJob', positionAppliedFor, validatedPositionAppliedFor)
}
